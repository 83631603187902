

import React from 'react';
import MainStyle from './MainStyle';
import Home from './components/Home';


function App() {
  return (
  <>
        <MainStyle/>
        <Home/>
        
  </>
  );
  }
export default App;
  