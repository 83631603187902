import styled from "@emotion/styled";


export const PostsWrapper =styled("div")`
label :PostsWrapper;
flex:9;
display: flex;
flex-wrap: wrap;
margin: 20px;

`